import { capitalize } from '@mui/material';
import moment from 'moment-timezone';
import { CallRecord, CallState, CallType, EmailSentOnCalls } from './calls';
import { LoggedInUser } from 'reducers/slices/userReducer';
import { logger } from './utils';

export interface Account {
  id: string,
  name: string,
  contacts: Contact[],
  faqs: Faq[],
  abbyBlock: string | null,
  abbyStage: string | null,
  abbyStage2: string | null,
  abbyTeam: string | null,
  accountManagerName: string | null,
  accountManagerEmail: string | null,
  businessStartDate: string | null,
  calibrationSpecialist: string | null,
  callAllowance: number | null,
  callRecordingType: string | null,
  clientTopGoal: string | null,
  commonMistakes: string | null,
  customHoursDaysOfOperation2: string | null,
  customHolidayHoursOfOperation: string | null,
  directionsToYourOffice: string | null,
  emailSentOnCalls: string | null,
  evoLevel: string | null,
  fax: string | null,
  greeting: string | null,
  greetingAudibleFile: string | null,
  hipaaCompliant: boolean,
  holidayHoursOfOperation: string | null,
  hostedsuiteLinks: string | null,
  hoursDaysOfOperation: string | null,
  howLongInBusiness: string | null,
  mainBusinessPhoneNumber: string | null,
  mainBusinessPhoneNumber2: string | null,
  moreInformation: string | null,
  oKToGiveOutFax: string | null,
  okToGiveOutPhysicalAddress: string | null,
  salesConsultantName: string | null,
  scriptsAndCallGuides: string | null,
  shippingCity: string | null,
  shippingCountry: string | null,
  shippingGeocodeAccuracy: string | null,
  shippingLatitude: string | null,
  shippingLongitude: string | null,
  shippingPostalCode: string | null,
  shippingState: string | null,
  shippingStreet: string | null,
  spanishCalls: boolean,
  spanishFlag: boolean,
  timeZone: string | null,
  tlc: boolean,
  tlcPopup: boolean,
  whatDoesYourOrganizationDo: string | null,
  website: string | null,

  isFromGlobalSearch?: boolean, // For FE use only
}

export interface Contact {
  accountId: string, // Manually populated as it's not returned from api

  id: string,
  name: string | null,
  firstName: string | null,
  lastName: string | null,
  firstNameSpelledPhonetically: string | null,
  lastNameSpelledPhonetically: string | null,
  email: string | null,
  okToGiveOutEmailAddress: string | null,
  aka: string | null,
  firstNameMask: string | null,
  lastNameMask: string | null,
  salutation: string | null,
  title: string | null,
  sortPositionInEvo: number | null,
  otherInfoInEVO: string | null,
  hideInEvo: boolean | null,
  hostedSuiteLinks: string | null,
  genderPronoun: string | null,

  daysAndTimesYouTakeLiveCalls: string | null,
  displayAsAlert: boolean | null,
  catchAll?: boolean,
  connectOnly: string | null,
  currentStatusAppliedDate: string | null,
  currentStatusExpirationDate: string | null,
  currentStatusNote: string | null,
  currentUnavailabilityExplanation: string | null,
  currentUnavailabilityExplanationOther: string | null,
  doNotConnect: string | null,
  doYouTakeCalls: string | null,
  mainBusinessPhoneNumber: string | null,
  mainBusinessPhoneNumber2: string | null,
  typesOfCallsTaken: string | null,
  otherTypesOfCallsTaken: string | null,
  unavailabilityExplanation: string | null,
  unavailabilityExplanationOther: string | null,
  whenUnavailableToTakeCalls: string | null,
  whenUnavailableToTakeCallsOther: string | null,
  additionalStatusNote: string | null,

  firstTransferPhone: string | null,
  firstTransferPhoneExt: string | null,
  firstTransferPhoneType: string | null,
  firstTransferPhoneTT: string | null,
  firstTransferPhoneTypeCustomName: string | null,
  secondTransferPhone: string | null,
  secondTransferPhoneExt: string | null,
  secondTransferPhoneType: string | null,
  secondTransferPhoneTT: string | null,
  secondTransferPhoneTypeCustomName: string | null,
  thirdTransferPhone: string | null,
  thirdTransferPhoneExt: string | null,
  thirdTransferPhoneType: string | null,
  thirdTransferPhoneTT: string | null,
  thirdTransferPhoneTypeCustomName: string | null,
  fourthTransferPhone: string | null,
  fourthTransferPhoneExt: string | null,
  fourthTransferPhoneType: string | null,
  fourthTransferPhoneTT: string | null,
  fourthTransferPhoneTypeCustomName: string | null,
  fifthTransferPhone: string | null,
  fifthTransferPhoneExt: string | null,
  fifthTransferPhoneType: string | null,
  fifthTransferPhoneTT: string | null,
  fifthTransferPhoneTypeCustomName: string | null,
  sixthTransferPhone: string | null,
  sixthTransferPhoneExt: string | null,
  sixthTransferPhoneType: string | null,
  sixthTransferPhoneTT: string | null,
  sixthTransferPhoneTypeCustomName: string | null,
  seventhTransferPhone: string | null,
  seventhTransferPhoneExt: string | null,
  seventhTransferPhoneType: string | null,
  seventhTransferPhoneTT: string | null,
  seventhTransferPhoneTypeCustomName: string | null,
  eighthTransferPhone: string | null,
  eighthTransferPhoneExt: string | null,
  eighthTransferPhoneType: string | null,
  eighthTransferPhoneTT: string | null,
  eighthTransferPhoneTypeCustomName: string | null,
  ninthTransferPhone: string | null,
  ninthTransferPhoneExt: string | null,
  ninthTransferPhoneType: string | null,
  ninthTransferPhoneTT: string | null,
  ninthTransferPhoneTypeCustomName: string | null,
  tenthTransferPhone: string | null,
  tenthTransferPhoneExt: string | null,
  tenthTransferPhoneType: string | null,
  tenthTransferPhoneTT: string | null,
  tenthTransferPhoneTypeCustomName: string | null,

  assignedVoicemail: string | null,
}

export interface ScheduledContactStatus {
  id: string,
  changeStatusTo: string | null,
  applyStatusOn: string | null,
  revertStatusOn: string | null,

  doNotConnect: string | null,
  connectOnly: string | null,
  unavailabilityExplanation: string | null,
  unavailabilityExplanationOther: string | null,
  statusNote: string | null,
}

export interface ConflictingContactStatus extends ScheduledContactStatus {
  isCurrent: boolean | null,
  isPreserved: boolean | null,
}

export interface Faq {
  id: string,
  answer: string,
  question: string,
}

export enum ACCOUNT_PANEL_STATUS {
  FIRST_LOAD,
  LOADING,
  LOADED,
  NOT_FOUND,
}
export const LAST_ACCOUNT_STORAGE = 'LAST_ACCOUNT_ID_STORAGE'

export interface PanelTask {
  name: string,
  link: string,
  accountId?: string,
  accountName?: string,
  callSid?: string,
  callerNumber?: string,
  direction?: string,
}

export const TASKS_STORAGE = 'TASKS_STORAGE'

export type TransferNumber = {
  id: string,
  label: string,
  value: string,
  type: string,
}

export const getContactName = (contact: Contact, justName = false) => {
  const {
    firstName, lastName,
    firstNameMask, lastNameMask,
    firstNameSpelledPhonetically,
    lastNameSpelledPhonetically,
    aka,
    salutation,
    genderPronoun,
    catchAll,
  } = contact

  let _firstName = firstNameMask || firstName || ''
  let _lastName = lastNameMask || lastName || ''

  if (justName) {
    return _firstName + ' ' + _lastName
  }

  let _salutation = salutation?.trim() || ''
  if (_salutation) {
    if (!_salutation.endsWith('.')) {
      _salutation += '.'
    }
    _salutation += ' '
  }

  if (firstNameSpelledPhonetically) _firstName += ` (${firstNameSpelledPhonetically})`
  if (lastNameSpelledPhonetically) _lastName += ` (${lastNameSpelledPhonetically})`

  let fullName = _salutation + _firstName + ' ' + _lastName
  if (!_firstName && !_lastName && !!aka) {
    let justAka = aka
    if (genderPronoun) justAka += ` [${genderPronoun}]`
    if (catchAll) justAka += ` [C]`
    return justAka
  }

  if (!_firstName && !_lastName) fullName = 'No Name'

  if (aka) fullName += ` (AKA: ${aka})`
  if (genderPronoun) fullName += ` [${genderPronoun}]`
  if (catchAll) fullName += ` [C]`

  return fullName
}

export const getAddress = (account: Account): string => {
  const address = []
  if (account.shippingStreet) address.push(account.shippingStreet)
  if (account.shippingCity) address.push(account.shippingCity)
  if (account.shippingState) address.push(account.shippingState)
  if (account.shippingPostalCode) address.push(account.shippingPostalCode)
  if (account.shippingCountry) address.push(account.shippingCountry)

  const addressString = address.join(' ')
  return addressString
}

interface HoursOfOperation {
  title: string,
  isChecked: boolean,
  start?: string,
  end?: string,
  isToday: boolean,
}
interface HolidayHoursOfOperation {
  holidayDate?: string;
  title: string,
  isChecked: boolean,
  start: string,
  end: string,
}
export interface HoursDaysOfOperation {
  [day: string]: HoursOfOperation,
}

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const parseHoursDaysOfOperation = (hoursDaysOfOperation: string | null) => {
  if (!hoursDaysOfOperation) return []

  try {
    const parsed: HoursDaysOfOperation = JSON.parse(hoursDaysOfOperation)
    const parsedHoursOfOperations = Object.values(parsed)

    const hoursOfOperations: HoursOfOperation[] = []
    const today = moment().format('dddd')

    days.forEach(day => {
      const hoursOfOperation = parsedHoursOfOperations.find(e => e.title.toLowerCase() === day)
      const dayString = capitalize(day)
      if (!hoursOfOperation) {
        hoursOfOperations.push({
          title: dayString,
          isChecked: false,
          isToday: dayString === today
        })
      } else {
        hoursOfOperations.push({
          title: dayString,
          isChecked: hoursOfOperation.isChecked,
          start: moment(hoursOfOperation.start, 'HH:mm').format('hh:mm A'),
          end: moment(hoursOfOperation.end, 'HH:mm').format('hh:mm A'),
          isToday: dayString === today
        })
      }
    })

    parsedHoursOfOperations
      .filter(e => !days.includes(e.title.toLowerCase()))
      .forEach(hoursOfOperation => {
        hoursOfOperations.push({
          isToday: hoursOfOperation.title.toLowerCase() === 'everyday',
          title: hoursOfOperation.title,
          isChecked: hoursOfOperation.isChecked,
          start: moment(hoursOfOperation.start, 'HH:mm').format('hh:mm A'),
          end: moment(hoursOfOperation.end, 'HH:mm').format('hh:mm A'),
        })
      })

    return hoursOfOperations
  } catch (err) {
    logger('An error occured when parsing JSON string',  hoursDaysOfOperation)
    return []
  }
}

export const parseHolidayHoursOfOperation = (holidayHoursOfOperation: string | null) => {
  if (!holidayHoursOfOperation) return []

  try {
    const parsed: HolidayHoursOfOperation = JSON.parse(holidayHoursOfOperation)
    const parsedHoursOfOperations = Object.values(parsed)

    const hoursOfOperations: HolidayHoursOfOperation[] = []

    parsedHoursOfOperations
      .forEach(hoursOfOperation => {
        hoursOfOperations.push({
          holidayDate: hoursOfOperation.holidayDate,
          title: hoursOfOperation.title,
          isChecked: hoursOfOperation.isChecked,
          start: moment(hoursOfOperation.start, 'HH:mm').format('hh:mm A'),
          end: moment(hoursOfOperation.end, 'HH:mm').format('hh:mm A'),
        })
      })

    return hoursOfOperations
  } catch (err) {
    logger('An error occured when parsing JSON string',  holidayHoursOfOperation)
    return []
  }
}

export const searchContacts = (contacts: Contact[], search: string) => {
  const searchKey = search.toLowerCase()
  if (!searchKey) return contacts

  return contacts.filter(contact => {
    const currentUnavailability = getContactUnavailability(getContactStatus(contact, true), contact)
    const unavailability = getContactUnavailability(getContactStatus(contact, false), contact)
    const transferNumbers = getTransferNumbers(contact)

    return (
      (getContactName(contact).toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.email && contact.email.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.title && contact.title.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.mainBusinessPhoneNumber && contact.mainBusinessPhoneNumber.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.mainBusinessPhoneNumber2 && contact.mainBusinessPhoneNumber2.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.typesOfCallsTaken && contact.typesOfCallsTaken.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.otherTypesOfCallsTaken && contact.otherTypesOfCallsTaken.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.doYouTakeCalls === 'Yes' && contact.currentStatusNote && contact.currentStatusNote.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.doYouTakeCalls === 'Yes' && contact.doNotConnect && contact.doNotConnect.split(';').join(',').toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.doYouTakeCalls !== 'Yes' && currentUnavailability && currentUnavailability.toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.doYouTakeCalls !== 'Yes' && contact.connectOnly && contact.connectOnly.split(';').join(',').toLowerCase().indexOf(searchKey) !== -1) ||
      (contact.doYouTakeCalls !== 'Yes' && contact.currentStatusNote && contact.currentStatusNote.toLowerCase().indexOf(searchKey) !== -1) ||
      (unavailability && unavailability.toLowerCase().indexOf(searchKey) !== -1) ||
      transferNumbers.some(transferNumber => transferNumber.value?.toLowerCase().indexOf(searchKey) !== -1)
    )
  })
}

export const sortContacts = (contacts: Contact[]) => {
  return contacts.sort((contactA, contactB) => {
    if (contactA.sortPositionInEvo !== contactB.sortPositionInEvo) {
      if (contactA.sortPositionInEvo === null) return 1
      if (contactB.sortPositionInEvo === null) return -1

      
      return (contactA.sortPositionInEvo || 0) - (contactB.sortPositionInEvo || 0)
    } else {
      return getContactName(contactA).toLowerCase() < getContactName(contactB).toLowerCase() ? -1 : 0
    }
  })
}

// https://help.salesforce.com/s/articleView?id=sf.admin_supported_timezone.htm&type=5
const TIMEZONE_MAP: Record<string, string> = {
  '(GMT-10:00) Hawaii': 'US/Hawaii',
  '(GMT-09:00) Alaska': 'US/Alaska',
  '(GMT-08:00) Pacific Time (US & Canada)': 'US/Pacific',
  '(GMT-07:00) Mountain Time (US & Canada)': 'US/Mountain',
  '(GMT-07:00) Arizona': 'US/Arizona',
  '(GMT-06:00) Central Time (US & Canada)': 'US/Central',
  '(GMT-05:00) Eastern Time (US & Canada)': 'US/Eastern',
  '(GMT-04:00) Atlantic Time (Canada)': 'Canada/Atlantic',
  '(GMT+10:00) Guam, Port Moresby': 'Pacific/Guam',
}


export const formatTimezone = (timezone: string | null) => {
  if (!timezone) return 'US/Pacific'

  // @ts-ignore
  if (TIMEZONE_MAP[timezone]) return TIMEZONE_MAP[timezone]
  return timezone
}

export type SFParsedTimezone = {
  code: string | null,
  name: string | null,
  offset: string | null,
}

export const parseSFTimezone = (timezone: string | null, removeDetails: boolean = true) => {
  if (!timezone) {
    return { code: 'US/Pacific', name: 'Pacific Time', offset: '-08:00' }; // Default to US/Pacific
  }

  const regex = /^\(GMT([+-]\d{2}:\d{2})\)\s(.+)$/;
  const match = timezone.match(regex);

  if (match) {
    const [, offset, name] = match;

    // Use the mapping to find the corresponding IANA timezone
    const mappedTimezone = TIMEZONE_MAP[timezone] || timezone;

    return {
      code: mappedTimezone, // IANA or fallback timezone
      name: removeDetails ? name.replace(/\(.*\)$/, '').trim() : name,
      offset,
    };
  }

  return { code: 'US/Pacific', name: 'Pacific Time', offset: '-08:00' }; // Fallback for invalid input
};

export const getAccountAttributes = (account?: Account | null) => {
  if (!account) return []

  const attributes = []
  if (account.spanishCalls) attributes.push('Spanish')
  if (account.hipaaCompliant) attributes.push('HIPAA')

  if (account.tlcPopup) attributes.push('TLC & Pop up')
  else if (account.tlc) attributes.push('TLC')

  if (account.timeZone?.startsWith('24/7')) attributes.push('24/7')

  if (account.abbyStage?.startsWith('0')) attributes.push('Abby Trial')
  else if (account.abbyStage?.startsWith('1')) attributes.push('Abby Trial')
  else if (account.abbyStage?.startsWith('2')) attributes.push('Abby Trial')
  else if (account.abbyStage?.startsWith('3')) attributes.push('Abby Trial')
  else if (account.abbyStage?.startsWith('4')) attributes.push('Abby Trial')

  if (account.emailSentOnCalls === EmailSentOnCalls.All) attributes.push('Email Sent on ALL Calls')
  if (account.emailSentOnCalls === EmailSentOnCalls.Live) attributes.push('Email Sent on LIVE Calls')

  if ([
    'Level 1', 'Level 2-a', 'Level 2-b', 'Level 2-c',
    'Level 2-d', 'Level 3', 'Level 4'
  ].includes(account.evoLevel || '')) attributes.push(account.evoLevel + ' Client')

  if (account.abbyStage2?.startsWith('1')) attributes.push('Onboarding')
  if (account.abbyStage2?.startsWith('2')) attributes.push('Onboarding')

  if (attributes.length) {
    return attributes
  }
  return []
}

export const getTransferNumbers = (contact: Contact): TransferNumber[] => {
  const numbers = [
    'first', 'second', 'third', 'fourth', 'fifth',
    'sixth', 'seventh', 'eighth', 'ninth', 'tenth'
  ];

  return numbers
    .map((num) => {
      const phoneKey = `${num}TransferPhone`;
      const extKey = `${num}TransferPhoneExt`;
      const typeKey = `${num}TransferPhoneTT`;
      const phoneTypeKey = `${num}TransferPhoneType`;
      const customNameKey = `${num}TransferPhoneTypeCustomName`;

      // @ts-ignore
      const phone = contact[phoneKey];
      // @ts-ignore
      const ext = contact[extKey];
      // @ts-ignore
      const type = contact[typeKey] as string;
      // @ts-ignore
      const phoneType = contact[phoneTypeKey];
      // @ts-ignore
      const customName = contact[customNameKey];

      if (!phone) return null;

      // Construct the value
      const value = ext ? `${phone} ? ${ext}` : phone;

      // Determine the label
      let label: string;
      if (!phoneType || phoneType === 'Other') {
        label = customName || phoneType || '';
      } else {
        label = phoneType || '';
      }

      // Set default label if label is still empty
      if (!label) {
        label = `${capitalize(num)} Transfer`;
      }

      // Generate a unique id using contact.id and num
      const id = `${contact.id}-${num}`;

      return {
        id,
        value: value as string,
        type,
        label,
      } as TransferNumber;
    })
    .filter((item): item is TransferNumber => item !== null);
};

export const getTypeOfCalls = (contact: Contact, isLoading = false) => {
  let typesOfCalls: string[] = []
  if (contact.typesOfCallsTaken) typesOfCalls = typesOfCalls.concat(contact.typesOfCallsTaken.split(','))
  if (contact.otherTypesOfCallsTaken) typesOfCalls = typesOfCalls.concat(contact.otherTypesOfCallsTaken.split(','))

  if (!typesOfCalls.length) return isLoading ? '' : 'None Specified'
  return typesOfCalls.join(', ')
}

export interface FormLink {
  name: string,
  link: string,
}

export const getAccountFormLinks = (hostedSuiteLinks: string): FormLink[] => {
  const links: FormLink[] = []
  if (!hostedSuiteLinks) return links

  const rawLinks: string[] = hostedSuiteLinks.replace('\r', '').replace('\n', '').split(';')
  rawLinks.forEach(rawLink => {
    const [name, link] = rawLink.split(',')
    links.push({ name: name?.trim(), link: link?.trim() })
  })

  return links
}

export const formatFormLinkUrl = (link: FormLink, selectedCall?: CallState | CallRecord, loggedInUser?: LoggedInUser | null, selectedContact?: Contact) => {
  const callSid = (selectedCall as CallState)?.callerCallSid || (selectedCall as CallRecord)?.callSid
  let linkUrl = decodeURIComponent(link.link)

  linkUrl = linkUrl.replace('#CALLERNUMBER#', selectedCall?.callerNumber || '')
  linkUrl = linkUrl.replace('#USER#', loggedInUser?.details?.name || '')
  if (callSid) {
    linkUrl = linkUrl.replace('#CALLERNAME#', (selectedCall?.callerNumber || '') + '***' +
      (callSid || ''))
  } else {
    linkUrl = linkUrl.replace('#CALLERNAME#', (selectedCall?.callerNumber || ''))
  }

  if (selectedCall && linkUrl.includes('form')) {
    if ((selectedCall as CallState).type === CallType.OUTBOUND || (selectedCall as CallRecord).direction === 'Outgoing') {
      const [url, searchParams] = linkUrl.split('?')
      const params = new URLSearchParams(searchParams || '')
      params.set('type', 'Outgoing')

      linkUrl = url + '?' + params.toString()
    }
  }

  if (selectedContact?.id && linkUrl.includes('form')) {
    const [url, searchParams] = linkUrl.split('?')
    const params = new URLSearchParams(searchParams || '')
    params.set('contactID', selectedContact.id)

    linkUrl = url + '?' + params.toString()
  }

  linkUrl = encodeURI(linkUrl)
  return linkUrl
}

const CURRENT_UNAVAILABILITY_MAP: Record<string, string> = {
  unavailable: 'Unavailable',
  'in a meeting': 'Meeting',
  'with a client': 'With a client',
  'on the other line': 'On the other line',
  'out of the office': 'OOO',
}

const WHEN_UNAVAILABLE_MAP: Record<string, string> = {
  'manually take a message and email to me': 'Email',
  'offer the caller your voicemail (we will be using your abbyconnect personalized voicemail)': 'VM',
  'give the caller a choice between leaving a message with the receptionist or voicemail': 'Email/VM',
  'send the caller to my cell phone voicemail': 'CellVM',
  'send the caller to my office voicemail (not a vm box that abby provides)': 'OffVM',
}

const OTHER_KEYWORDS = [
  'other',
  'other (please contact your account manager)',
]

export const getContactStatus = (contact: Contact, isCurrent: boolean): ScheduledContactStatus => {
  return {
    id: 'contact',
    changeStatusTo: contact.doYouTakeCalls,
    applyStatusOn: contact.currentStatusAppliedDate,
    revertStatusOn: contact.currentStatusExpirationDate,
  
    doNotConnect: contact.doNotConnect,
    connectOnly: contact.connectOnly,
    unavailabilityExplanation: isCurrent ? contact.currentUnavailabilityExplanation : contact.unavailabilityExplanation,
    unavailabilityExplanationOther: isCurrent ? contact.currentUnavailabilityExplanationOther : contact.unavailabilityExplanationOther,
    statusNote: isCurrent ? contact.currentStatusNote : contact.additionalStatusNote,
  }
}

export const getContactUnavailability = (contactStatus: ScheduledContactStatus, contact?: Contact): string => {
  const unavailability: string[] = []

  const unavailabilityExplanation = contactStatus.unavailabilityExplanation

  const unavailabilityExplanationOther = contactStatus.unavailabilityExplanationOther

  const currentUnavailabilityExplanation = (unavailabilityExplanation || '').toLowerCase()
  const mappedExplanation = CURRENT_UNAVAILABILITY_MAP[currentUnavailabilityExplanation]
  if (mappedExplanation) {
    unavailability.push(mappedExplanation)
  } else if (OTHER_KEYWORDS.includes(currentUnavailabilityExplanation)) {
    unavailability.push(unavailabilityExplanationOther || 'Other')
  } else if (unavailabilityExplanation) {
    unavailability.push(unavailabilityExplanation)
  }


  if (contact) {
    const whenUnavailable = (contact.whenUnavailableToTakeCalls || '').toLowerCase()
    const mappedWhen = WHEN_UNAVAILABLE_MAP[whenUnavailable]
    if (mappedWhen) {
      unavailability.push(mappedWhen)
    } else if (OTHER_KEYWORDS.includes(whenUnavailable)) {
      unavailability.push(contact.whenUnavailableToTakeCallsOther || '')
    } else if (contact.whenUnavailableToTakeCalls) {
      unavailability.push(contact.whenUnavailableToTakeCalls)
    }
  }

  return unavailability.join(' /// ')
}

export const HOURS_DAYS_OF_OPERATION_LABEL = 'Hours/Days of Operation'
export const HOLIDAY_HOURS_OF_OPERATION_LABEL = 'Holiday Hours/Days of Operation'