import { addTask } from "reducers/slices/accountPanelReducer"
import { AppThunk } from "reducers/types"
import { Account, FormLink, PanelTask, formatFormLinkUrl } from "utils/accounts"
import { CallRecord, CallType } from "utils/calls"

export const getActiveAccount = (): AppThunk<Account | undefined> => {
  return (dispatch, getState) => {
    return getState().accountPanel.activeAccount
  }
}

export const handleSelectedCallLink = (link: FormLink): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedCall = state.callSlot.selectedCall
    const account = state.accountPanel.activeAccount
    const loggedInUser = state.user.user
    const selectedContact = state.accountPanel.selectedContact


    let linkUrl = formatFormLinkUrl(link, selectedCall, loggedInUser)
    if (!linkUrl.startsWith('https://')) {
      linkUrl = linkUrl.replace('http://', 'https://')
      if (!linkUrl.startsWith('https://')) {
        linkUrl = 'https://' + linkUrl
      }
    }

    let name = ''
    if (selectedCall?.callerNumber) {
      name += selectedCall.callerNumber + ' - '
    }
    name += link.name + ' - ' + account.name

    dispatch(addTask({
      name,
      link: linkUrl,
      callSid: selectedCall?.callerCallSid,
      callerNumber: selectedCall?.callerNumber,
      direction: selectedCall?.type === CallType.OUTBOUND ? 'Outgoing' : 'Incoming',
      accountId: account.id,
      accountName: account.name,
    }))
  }
}

export const handleCallRecordLink = (link: FormLink, callRecord?: CallRecord): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const loggedInUser = state.user.user
    const selectedContact = state.accountPanel.selectedContact

    const linkUrl = formatFormLinkUrl(link, callRecord, loggedInUser, selectedContact)

    const task: PanelTask = {
      name: '',
      link: linkUrl,
      callSid: callRecord?.callSid || undefined,
      direction: callRecord?.direction || undefined,
      accountId: callRecord?.account?.id || undefined,
      accountName: callRecord?.account?.name || undefined,
    }
    let name = ''
    if (callRecord?.direction === 'Incoming') {
      if (callRecord.callerNumber) {
        name += callRecord.callerNumber + ' - '
        task.callerNumber = callRecord?.callerNumber || undefined
      }
    } else {
      if (callRecord?.outboundCallNumberDialed) {
        name += callRecord?.outboundCallNumberDialed + ' - '
        task.callerNumber = callRecord?.outboundCallNumberDialed || undefined
      }
    }
    name += link.name + ' - ' + callRecord?.account?.name
    task.name = name

    dispatch(addTask(task))
  }
}

export const getAccountCache = (key: string): AppThunk<Account | undefined> => {
  return (dispatch, getState) => {
    const state = getState()
    return state.cache[key]
  }
}

export const getSelectedTask = (): AppThunk<PanelTask | undefined> => {
  return (dispatch, getState) => {
    const state = getState()
    return state.accountPanel.tasks.find(e => e.name === state.accountPanel.selectedTask)
  }
}
