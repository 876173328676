import { FC, useMemo } from 'react'
import { Box, Tooltip, Typography } from '@mui/material';
import { Email, Favorite, Flag, Notifications, PhoneDisabled, PhoneForwarded, Star, Warning } from '@mui/icons-material';
import { CallState, CallType, EmailSentOnCalls } from 'utils/calls';
import SpanishFlagIcon from 'assets/spanish-flag.png'
import HipaaIcon from 'assets/HipaaIcon';
import FlagNotes from './FlagNotes';

interface IProps {
  callState: CallState,
  textColor: string,
  small?: boolean,
}

const CallerInfo: FC<IProps> = ({
  callState,
  textColor,
  small,
}: IProps) => {
  const {
    name,
    type,
    flagNotes,
    hipaaCompliant,
    commonMistakes,
    emailSentOnCalls,
    spanishFlag,
    tlc,
    tlcPopup,
    abbyStage2,
    callerNumber,
    callerLeftWarmTransfer,
  } = callState;

  const headerIconsCount = useMemo(() => {
    let count = 0
    if (hipaaCompliant) count += 1
    if (commonMistakes) count += 1
    if ([EmailSentOnCalls.All, EmailSentOnCalls.Live].includes(emailSentOnCalls as EmailSentOnCalls)) count += 1
    if (spanishFlag) count += 1
    if (tlc || tlcPopup) count += 1
    if (type === CallType.OUTBOUND) count += 1
    if (abbyStage2?.startsWith('1') || abbyStage2?.startsWith('2')) count += 1
    if (flagNotes?.length) count += 1
    if (callerLeftWarmTransfer) count += 1

    return count
  }, [hipaaCompliant, commonMistakes, emailSentOnCalls, spanishFlag, type, tlc, tlcPopup, abbyStage2, flagNotes, callerLeftWarmTransfer])

  return (
    <Box height={60}>
      <Box maxHeight={36} display="flex" alignItems="flex-start" position="relative" textOverflow="ellipsis">
        <Box position="absolute" display="flex">
          {!!flagNotes?.length &&
            <Tooltip title={<FlagNotes flagNotes={flagNotes} />}>
              <Flag sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {(tlc || tlcPopup) &&
            <Tooltip title={tlcPopup ? 'TLC & Pop up' : 'TLC'}>
              <Warning sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {hipaaCompliant &&
            <Tooltip title="HIPAA">
              <HipaaIcon sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {emailSentOnCalls === EmailSentOnCalls.All &&
            <Tooltip title="Email sent on all calls">
              <Star sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {emailSentOnCalls === EmailSentOnCalls.Live &&
            <Tooltip title="Email sent on live calls">
              <Email sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {(abbyStage2?.startsWith('1') || abbyStage2?.startsWith('2')) &&
            <Tooltip title="Onboarding">
              <Notifications sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {spanishFlag &&
            <Tooltip title="Spanish">
              <img src={SpanishFlagIcon} alt="" width={20} height={20} style={{ marginRight: '4px' }} />
            </Tooltip>
          }
          {type === CallType.OUTBOUND &&
            <Tooltip title="Outbound call">
              <PhoneForwarded sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {!!callerLeftWarmTransfer &&
            <Tooltip title="Caller has left the call">
              <PhoneDisabled sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
          {commonMistakes &&
            <Tooltip title="Bolo Instructions">
              <Favorite sx={{ fontSize: '18px', mr: '4px', color: textColor }} />
            </Tooltip>
          }
        </Box>
        <Tooltip title={name || 'Anonymous'} placement="top">
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 700,
              color: textColor,
              textIndent: `${headerIconsCount * 22}px`,
              maxWidth: small ? 185 : 210,
              display: '-webkit-box',
              'WebkitBoxOrient': 'vertical',
              'WebkitLineClamp': 2,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {name || 'Anonymous'}
          </Typography>
        </Tooltip>
      </Box>
      <Typography sx={{ mt: '2px', fontSize: '12px', fontWeight: 600, color: textColor }}>{callerNumber}</Typography>
    </Box>
  );
}

export default CallerInfo