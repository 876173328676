import { useEffect } from "react";
import { CallState, CallStateState, CallType } from "utils/calls";
import { activeCallSelector } from "reducers/selector/callSelector";
import { useAppSelector } from "reducers/hooks";
import { useDispatch } from "react-redux";
import { setIsRinging } from "reducers/slices/UIReducer";

const shouldCallRing = (callState: CallState) => {
  return (
    callState &&
    !callState.isProcessing &&
    callState.state === CallStateState.UNANSWERED &&
    callState.type === CallType.INBOUND
  )
}

/**
 * Keep track of calls and play or stop ringtone when appropriate
 */
const useRingtonePlayer = () => {
  const activeCall = useAppSelector(activeCallSelector);
  const callSlots = useAppSelector(state => state.callSlot.callSlots);
  const calls = useAppSelector(state => state.call);
  const isRinging = useAppSelector(state => state.ui.isRinging); // Track the current ringing state
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeCall) {
      // If there's an active call, stop ringing immediately.
      if (isRinging) {
        dispatch(setIsRinging(false));
      }
      return;
    }

    const filledSlots = Object.values(callSlots).filter(slot => !!slot.claimedBy);

    if (filledSlots.length === 0) {
      // No claimed calls, stop ringing.
      if (isRinging) dispatch(setIsRinging(false));
      return;
    }

    const shouldRing = filledSlots.some(slot => {
      if (slot.claimedBy) {
        const callState = calls[slot.claimedBy];
        return callState ? shouldCallRing(callState) : false;
      }
      return false;
    });

    if (shouldRing && !isRinging) {
      dispatch(setIsRinging(true));
    } else if (!shouldRing && isRinging) {
      dispatch(setIsRinging(false));
    }
  }, [activeCall, callSlots, calls, isRinging, dispatch]);

  return null;
};

export default useRingtonePlayer