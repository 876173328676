import React, { useMemo } from "react";
import { Tooltip, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { getAccountAttributes } from "utils/accounts";
import { EmailSentOnCalls } from '../../../../utils/calls';
import {
  Email,
  Favorite,
  Notifications,
  Star,
  Warning,
} from '@mui/icons-material';
import HipaaIcon from '../../../../assets/HipaaIcon';
import SpanishFlagIcon from '../../../../assets/spanish-flag.png';

const AccountName = () => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount);
  const {hipaaCompliant, commonMistakes, emailSentOnCalls, tlc, tlcPopup, abbyStage2, spanishFlag} = account

  const additionalInfo = useMemo(() => {
    const attributes = getAccountAttributes(account)

    if (attributes.length) {
      return `(${attributes.join(', ')})`
    }
    return ''
  }, [account])

  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: '18px',
        color: '#3D3D3D',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >

      <Typography sx={{ fontWeight: 600, fontSize: '24px', mr: 1, color: '#000' }} component="span">
          {(tlc || tlcPopup) &&
            <Tooltip title={tlcPopup ? 'TLC & Pop up' : 'TLC'}>
              <Warning sx={{ fontSize: '18px', mr: '4px'}} />
            </Tooltip>
          }
          {hipaaCompliant &&
            <Tooltip title="HIPAA">
              <HipaaIcon sx={{ fontSize: '18px', mr: '4px'}} />
            </Tooltip>
          }
          {emailSentOnCalls === EmailSentOnCalls.All &&
            <Tooltip title="Email sent on all calls">
              <Star sx={{ fontSize: '18px', mr: '4px'}} />
            </Tooltip>
          }
          {emailSentOnCalls === EmailSentOnCalls.Live &&
            <Tooltip title="Email sent on live calls">
              <Email sx={{ fontSize: '18px', mr: '4px'}} />
            </Tooltip>
          }
          {(abbyStage2?.startsWith('1') || abbyStage2?.startsWith('2')) &&
            <Tooltip title="Onboarding">
              <Notifications sx={{ fontSize: '18px', mr: '4px' }} />
            </Tooltip>
          }
          {spanishFlag &&
            <Tooltip title="Spanish">
              <img src={SpanishFlagIcon} alt="" width={20} height={20} style={{ marginRight: '4px' }} />
            </Tooltip>
          }
          {commonMistakes &&
            <Tooltip title="Bolo Instructions">
              <Favorite sx={{ fontSize: '18px', mr: '4px' }} />
            </Tooltip>
          }
          {account.name}
      </Typography>
      <Tooltip title={additionalInfo}>
        <span>
          {additionalInfo}
        </span>
      </Tooltip>
    </Typography>
  );
};

export default AccountName;
